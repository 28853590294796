<template>
    <div>
        <div v-if="redes.facebook != null || redes.instagram != null || redes.whatsapp != null" class="float-tab bg-general d-flex flex-column justify-content-center h-auto">
            <div v-if="redes.facebook != null" class="row mx-0 justify-center" @click="openredSocial(redes.facebook)">
                <i class="icon-facebook f-30 text-white cr-pointer" />
            </div>
            <div v-if="redes.instagram != null" class="row mx-0 justify-center" @click="openredSocial(redes.instagram)">
                <i class="icon-instagram f-30 text-white cr-pointer" />
            </div>
            <div v-if="redes.whatsapp != null" class="row mx-0 justify-center" @click="openWhastApp">
                <i class="icon-whatsapp f-30 text-white cr-pointer" />
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            redesSociales: {}
        }
    },
    computed:{
        ...mapGetters({
            redes:'home/redes',
        })
    },
    methods: {
        openredSocial(item){
            let route= item
            window.open(route)
        },
        openWhastApp(){
            const numero = this.redes.whatsapp
            const mensaje = `Hola! Quiero comprar 😃`
            window.open(`https://api.whatsapp.com/send?phone=${numero}&text=` + encodeURIComponent(mensaje));
        },
    }
}
</script>
<style lang="scss" scoped>


.float-tab{
    width: 46px;
    right: 33px;
    color: #FFFFFF;
    height: 148px;
    right: 0px;
    position: absolute;
    border-radius: 12px 0px 0px 12px;
    top: 391px;
	z-index: 110 !important;

}

.space-screenshot{
    position: absolute;
    width: 100%;
    height: 459px;
    top: 0px;
}
</style>
